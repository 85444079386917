$dimension-sizes: 1250;
$rem-text-size: 0.625;
$px-text-size: 10;



// position
.before-pos {
    &::before {
        content: '';
        position: absolute;
        margin: auto;
        border-radius: #{calc(4/16)}rem;
    }
}

// position
.after-pos {
    &::after {
        content: '';
        position: absolute;
        margin: auto;
        border-radius: #{calc(4/16)}rem;
    }
}

@for $i from 0 to $dimension-sizes {

    // height
    .height-#{$i}px {
        height: #{calc($i/16)}rem;
    }

    .max-height-#{$i}px {
        max-height: #{calc($i/16)}rem;
    }

    .min-height-#{$i}px {
        min-height: #{calc($i/16)}rem;
    }

    // height in percentage
    @if $i<=100 {
        .height-#{$i} {
            height: #{$i}#{'%'};
        }

        .h-#{$i} {
            height: #{$i}#{'%'};
        }

        .height-#{$i}vw {
            height: #{$i}vw;
        }

        .h-#{$i}vw {
            height: #{$i}vw;
        }
    }


    // width
    .width-#{$i}px {
        width: #{calc($i/16)}rem;
    }

    .max-width-#{$i}px {
        max-width: #{calc($i/16)}rem;
    }

    .min-width-#{$i}px {
        min-width: #{calc($i/16)}rem;
    }

    @if $i<=100 {
        .width-#{$i} {
            width: #{$i}#{'%'};
        }

        .max-width-#{$i} {
            max-width: #{$i}#{'%'};
        }

        .min-width-#{$i} {
            min-width: #{$i}#{'%'};
        }

        .w-#{$i} {
            width: #{$i}#{'%'};
        }
    }


    // border
    .border-#{$i}px {
        border-width: #{calc($i/16)}rem !important;
    }

    .border-bottom-#{$i}px {
        border-bottom-width: #{calc($i/16)}rem !important;
    }

    .border-end-#{$i}px {
        border-right-width: #{calc($i/16)}rem !important;
    }

    .border-start-#{$i}px {
        border-left-width: #{calc($i/16)}rem !important;
    }

    .border-top-#{$i}px {
        border-top-width: #{calc($i/16)}rem !important;
    }


    // rounded
    .rounded-#{$i}px {
        border-radius: #{calc($i/16)}rem !important;
    }

    .rounded-bottom-start-#{$i}px {
        border-radius: #{calc($i/16)}rem !important;
    }

    .rounded-bottom-end-#{$i}px {
        border-radius: #{calc($i/16)}rem !important;
    }

    .rounded-top-start-#{$i}px {
        border-radius: #{calc($i/16)}rem !important;
    }

    .rounded-top-end-#{$i}px {
        border-radius: #{calc($i/16)}rem !important;
    }


    // padding
    .padding-#{$i}px {
        padding: #{calc($i/16)}rem;
    }

    .padding-bottom-#{$i}px {
        padding-bottom: #{calc($i/16)}rem !important;
    }

    .padding-end-#{$i}px {
        padding-right: #{calc($i/16)}rem !important;
    }

    .padding-start-#{$i}px {
        padding-left: #{calc($i/16)}rem !important;
    }

    .padding-top-#{$i}px {
        padding-top: #{calc($i/16)}rem !important;
    }


    // margin
    .margin-#{$i}px {
        margin: #{calc($i/16)}rem;
    }

    .margin-bottom-#{$i}px {
        margin-bottom: #{calc($i/16)}rem;
    }

    .margin-end-#{$i}px {
        margin-right: #{calc($i/16)}rem;
    }

    .margin-start-#{$i}px {
        margin-left: #{calc($i/16)}rem;
    }

    .margin-top-#{$i}px {
        margin-top: #{calc($i/16)}rem;
    }


    // text size
    @if $i>=10 or $i<=64 {
        .text-#{$i}px {
            font-size: #{calc(calc($rem-text-size * $i) / $px-text-size)}rem;
        }
    }


    // line height
    .line-height-#{$i}px {
        line-height: #{calc(calc($rem-text-size * $i) / $px-text-size)}rem;
    }


    // bottom
    .bottom-#{$i}px {
        bottom: #{calc($i/16)}rem;
    }

    .bottom-#{$i}px-i {
        bottom: #{calc($i/16)}rem !important;
    }

    .bottom-#{$i} {
        bottom: #{$i}#{'%'} !important;
    }

    .bottom--#{$i}px {
        bottom: #{'-'}#{calc($i/16)}rem;
    }

    .bottom--#{$i}px-i {
        bottom: #{'-'}#{calc($i/16)}rem !important;
    }


    // end
    .end-#{$i}px {
        right: #{calc($i/16)}rem;
    }

    .end-#{$i}px-i {
        right: #{calc($i/16)}rem !important;
    }

    .end-#{$i} {
        right: #{$i}#{'%'} !important;
    }

    .end--#{$i}px {
        right: #{'-'}#{calc($i/16)}rem;
    }

    .end--#{$i}px-i {
        right: #{'-'}#{calc($i/16)}rem !important;
    }


    // start
    .start-#{$i}px {
        left: #{calc($i/16)}rem;
    }

    .start-#{$i}px-i {
        left: #{calc($i/16)}rem !important;
    }

    .start-#{$i} {
        left: #{$i}#{'%'} !important;
    }

    .start--#{$i}px {
        left: #{'-'}#{calc($i/16)}rem;
    }

    .start--#{$i}px-i {
        left: #{'-'}#{calc($i/16)}rem !important;
    }


    // top
    .top-#{$i}px {
        top: #{calc($i/16)}rem;
    }

    .top-#{$i}px-i {
        top: #{calc($i/16)}rem !important;
    }

    .top-#{$i} {
        top: #{$i}#{'%'} !important;
    }

    .top--#{$i}px {
        top: #{'-'}#{calc($i/16)}rem;
    }

    .top--#{$i}px-i {
        top: #{'-'}#{calc($i/16)}rem !important;
    }


    // icon
    .icon-#{$i}px {
        height: #{calc($i/16)}rem;
        width: #{calc($i/16)}rem;
    }

    .icon-#{$i}px svg {
        height: #{calc($i/16)}rem;
        width: #{calc($i/16)}rem;
    }


    // z-index
    .z-index-#{$i} {
        z-index: #{$i};
    }


    // opacity
    @if $i>=0 or $i<=10 {
        .opacity-#{$i} {
            opacity: #{calc(calc($i * 10) / 100)};
        }
    }


    // skew transform
    @if $i<=100 {
        .skew-#{$i}deg {
            transform-origin: 100% 0;
            transform: skewY(#{$i}#{'deg'});
        }

        .skew--#{$i}deg {
            transform-origin: 100% 0;
            transform: skewY(#{'-'}#{$i}#{'deg'});
        }
    }


    // before
    .before-#{$i}px {
        &::before {
            content: '';
            position: absolute;
            top: #{calc($i/16)}rem;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: #{calc(4/16)}rem;
        }
    }

    .before--#{$i}px {
        &::before {
            content: '';
            position: absolute;
            top: #{'-'}#{calc($i/16)}rem;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: #{calc(4/16)}rem;
        }
    }

    // top
    .before-pos-top-#{$i}px {
        &::before {
            content: '';
            position: absolute;
            top: #{calc($i/16)}rem;
            margin: auto;
            border-radius: #{calc(4/16)}rem;
        }
    }

    .before-pos-top--#{$i}px {
        &::before {
            content: '';
            position: absolute;
            top: #{'-'}#{calc($i/16)}rem;
            margin: auto;
            border-radius: #{calc(4/16)}rem;
        }
    }

    // left
    .before-pos-start-#{$i}px {
        &::before {
            content: '';
            left: #{calc($i/16)}rem;
        }
    }

    .before-pos-start--#{$i}px {
        &::before {
            content: '';
            left: #{'-'}#{calc($i/16)}rem;
        }
    }

    // bottom
    .before-pos-bottom-#{$i}px {
        &::before {
            content: '';
            bottom: #{calc($i/16)}rem;
        }
    }

    .before-pos-bottom--#{$i}px {
        &::before {
            content: '';
            bottom: #{'-'}#{calc($i/16)}rem;
        }
    }

    // right
    .before-pos-end-#{$i}px {
        &::before {
            content: '';
            right: #{calc($i/16)}rem;
        }
    }

    .before-pos-bottom--#{$i}px {
        &::before {
            content: '';
            right: #{'-'}#{calc($i/16)}rem;
        }
    }


    // 
    .before-border-#{$i}px {
        &::after {
            content: '';
            height: #{calc($i/16)}rem;
        }
    }

    .before-size-#{$i}px {
        &::before {
            content: '';
            width: #{calc($i/16)}rem;
        }
    }

    // after
    .after-#{$i}px {
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: #{calc($i/16)}rem;
            right: 0;
            margin: auto;
            border-radius: #{calc(4/16)}rem;
        }
    }

    .after--#{$i}px {
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: #{'-'}#{calc($i/16)}rem;
            right: 0;
            margin: auto;
            border-radius: #{calc(4/16)}rem;
        }
    }

    // top
    .after-pos-top-#{$i}px {
        &::after {
            content: '';
            top: #{calc($i/16)}rem;
        }
    }

    .after-pos-top--#{$i}px {
        &::after {
            content: '';
            top: #{'-'}#{calc($i/16)}rem;
        }
    }

    // left
    .after-pos-start-#{$i}px {
        &::after {
            content: '';
            left: #{calc($i/16)}rem;
        }
    }

    .after-pos-start--#{$i}px {
        &::after {
            content: '';
            left: #{'-'}#{calc($i/16)}rem;
        }
    }

    // bottom
    .after-pos-bottom-#{$i}px {
        &::after {
            content: '';
            bottom: #{calc($i/16)}rem;
        }
    }

    .after-pos-bottom--#{$i}px {
        &::after {
            content: '';
            bottom: #{'-'}#{calc($i/16)}rem;
        }
    }

    // right
    .after-pos-end-#{$i}px {
        &::after {
            content: '';
            right: #{calc($i/16)}rem;
        }
    }

    .after-pos-bottom--#{$i}px {
        &::after {
            content: '';
            right: #{'-'}#{calc($i/16)}rem;
        }
    }


    .after-border-#{$i}px {
        &::after {
            content: '';
            height: #{calc($i/16)}rem;
        }
    }

    .after-size-#{$i}px {
        &::after {
            content: '';
            width: #{calc($i/16)}rem;
        }
    }


    // grid
    .grid-gap-#{$i}px {
        grid-gap: #{calc($i/16)}rem;
    }

    .grid-row-height-#{$i}px {
        grid-auto-rows: #{calc($i/16)}rem;
        /* Creates n-number of rows with 100px height */
    }

    .grid-column-count-#{$i} {
        grid-template-columns: repeat(#{$i}, 1fr);
    }

}