@use "sass:map";


// **************************************
// *                THEME               *
// **************************************

// google
$g-bg-theme-color: "#EAECEF";
$g-b-bg-theme-color: "#4285F4";
$g-g-bg-theme-color: "#0F9D58";
$g-r-bg-theme-color: "#DB4437";
$g-o-bg-theme-color: "#FFA500";
$g-y-bg-theme-color: "#F4B400";
$g-w-fg-theme-color: "#FFFFFF";
$g-b-fg-theme-color: "#000000";

// facebook
$fb-bg-theme-color: "#4267B2";
$fb-fg-theme-color: "#FFFFFF";

// twitter
$tw-bg-theme-color: "";
$tw-fg-theme-color: "";

// solid colors
$cx-drk-bg-theme-color: "#333333";
$cx-blk-bg-theme-color: "#000000";
$cx-gry-bg-theme-color: "#E6E6E6";
$cx-sky-bg-theme-color: "#CCCCFF";
$cx-gld-bg-theme-color: "#FFFFAB";
$cx-pnk-bg-theme-color: "#FFC2FF";
$cx-brw-bg-theme-color: "#663333";
$cx-red-bg-theme-color: "#C70202";
$cx-blu-bg-theme-color: "#265FB2";
$cx-blu-cyan-bg-theme-color: "#5191B2";
$cx-blu-dark-bg-theme-color: "#1b4685";
$cx-blu-light-bg-theme-color: "#eaeff7";
$cx-blu-lighter-bg-theme-color: "#4387eb2b";
$cx-grn-bg-theme-color: "#4BCC8C";
$cx-yel-bg-theme-color: "#FFCC00";
$cx-whi-bg-theme-color: "#FFFFFF";
$cx-lig-bg-theme-color: "#f8f9fa";
$cx-w-fg-theme-color: "#FFFFFF";
$cx-b-fg-theme-color: "#000000";

$cx-success-info-theme-color: "#d9fbd0";
$cx-success-info-dark-theme-color: "#1c6c09";
$cx-success-info-border-theme-color: "#bee8b4";

$cx-warning-info-theme-color: "#ffefca";
$cx-warning-info-dark-theme-color: "#bc3803";
$cx-warning-info-border-theme-color: "#ffcc85";

$cx-danger-info-theme-color: "#ffe0db";
$cx-danger-info-dark-theme-color: "#b81800";
$cx-danger-info-border-theme-color: "#fabcb3";

$cx-disabled-info-theme-color: "#e3e6ed";
$cx-disabled-info-dark-theme-color: "#141824";
$cx-disabled-info-border-theme-color: "#cbd0dd";

// "#0dcaf0";
$cx-info-info-theme-color: "#0dcaf09c";
$cx-info-info-dark-theme-color: "#023742";
$cx-info-info-border-theme-color: "#0388a3fe";

.sample_color {
    color: #00b0d3;
}

// transparent colors
$cx-transparent-drk-bg-theme-color: "#33333399";
$cx-transparent-blk-bg-theme-color: "#00000099";
$cx-transparent-gry-bg-theme-color: "#e6e6e699";
$cx-transparent-sky-bg-theme-color: "#ccccff99";
$cx-transparent-gld-bg-theme-color: "#ffffab99";
$cx-transparent-pnk-bg-theme-color: "#ffc2ff99";
$cx-transparent-brw-bg-theme-color: "#66333399";
$cx-transparent-red-bg-theme-color: "#C7020299";
$cx-transparent-blu-bg-theme-color: "#265FB299";
$cx-transparent-blu-cyan-bg-theme-color: "#5191B299";
$cx-transparent-blu-dark-bg-theme-color: "#1b468522";
$cx-transparent-blu-light-bg-theme-color: "#eaeff799";
$cx-transparent-blu-lighter-bg-theme-color: "#4387eb2b";
$cx-transparent-grn-bg-theme-color: "#4BCC8C99";
$cx-transparent-yel-bg-theme-color: "#FFCC0099";
$cx-transparent-whi-bg-theme-color: "#FFFFFF99";
$cx-transparent-lig-bg-theme-color: "#f8f9fa99";
$cx-transparent-none-bg-theme-color: "transparent";
$cx-transparent-w-fg-theme-color: "#FFFFFF";
$cx-transparent-b-fg-theme-color: "#000000";

// selected colors
$cx-selected-drk-bg-theme-color: "#33333399";
$cx-selected-blk-bg-theme-color: "#00000099";
$cx-selected-gry-bg-theme-color: "#e6e6e699";
$cx-selected-sky-bg-theme-color: "#ccccff99";
$cx-selected-gld-bg-theme-color: "#ffffab99";
$cx-selected-pnk-bg-theme-color: "#ffc2ff99";
$cx-selected-brw-bg-theme-color: "#66333399";
$cx-selected-red-bg-theme-color: "#C7020299";
$cx-selected-blu-bg-theme-color: "#265FB2";
$cx-selected-blu-cyan-bg-theme-color: "#6dabc599";
$cx-selected-blu-dark-bg-theme-color: "#1b468599";
$cx-selected-blu-light-bg-theme-color: "#eaeff799";
$cx-selected-blu-lighter-bg-theme-color: "#4387eb2b";
$cx-selected-grn-bg-theme-color: "#4BCC8C99";
$cx-selected-yel-bg-theme-color: "#FFCC0099";
$cx-selected-whi-bg-theme-color: "#FFFFFF99";
$cx-selected-lig-bg-theme-color: "#f8f9fa99";
$cx-selected-w-fg-theme-color: "#FFFFFF";
$cx-selected-b-fg-theme-color: "#000000";

// hover colors
$cx-hover-drk-bg-theme-color: "#33333399";
$cx-hover-blk-bg-theme-color: "#00000099";
$cx-hover-gry-bg-theme-color: "#e6e6e699";
$cx-hover-sky-bg-theme-color: "#ccccff99";
$cx-hover-gld-bg-theme-color: "#ffffab99";
$cx-hover-pnk-bg-theme-color: "#ffc2ff99";
$cx-hover-brw-bg-theme-color: "#66333399";
$cx-hover-red-bg-theme-color: "#C7020299";
$cx-hover-blu-bg-theme-color: "#265FB299";
$cx-hover-blu-cyan-bg-theme-color: "#5191B299";
$cx-hover-blu-dark-bg-theme-color: "#1b468599";
$cx-hover-blu-light-bg-theme-color: "#eaeff799";
$cx-hover-blu-lighter-bg-theme-color: "#4387eb2b";
$cx-hover-grn-bg-theme-color: "#4BCC8C99";
$cx-hover-yel-bg-theme-color: "#FFCC0099";
$cx-hover-whi-bg-theme-color: "#FFFFFF99";
$cx-hover-lig-bg-theme-color: "#f8f9fa99";
$cx-hover-w-fg-theme-color: "#FFFFFF";
$cx-hover-b-fg-theme-color: "#000000";

.color {
    text-emphasis-color: rgb(255, 224, 219);
    background-color: rgb(250, 188, 179);
}

$themes: (
    "theme-colors": ( // google
        ("google-theme": ($g-bg-theme-color, $g-b-fg-theme-color)),
        ("google-blue-theme": ($g-b-bg-theme-color, $g-w-fg-theme-color)),
        ("google-red-theme": ($g-r-bg-theme-color, $g-w-fg-theme-color)),
        ("google-orange-theme": ($g-o-bg-theme-color, $g-w-fg-theme-color)),
        ("google-yellow-theme": ($g-y-bg-theme-color, $g-w-fg-theme-color)),
        ("google-green-theme": ($g-g-bg-theme-color, $g-w-fg-theme-color)),

        // facebook
        ("facebook-theme": ($fb-bg-theme-color, $fb-fg-theme-color)),

        // twitter
        ("twitter-theme": ($tw-bg-theme-color, $tw-fg-theme-color)),

        // 
        ("dark-theme": ($cx-drk-bg-theme-color, $cx-w-fg-theme-color)),
        ("black-theme": ($cx-blk-bg-theme-color, $cx-w-fg-theme-color)),
        ("grey-theme": ($cx-gry-bg-theme-color, $cx-b-fg-theme-color)),
        ("sky-theme": ($cx-sky-bg-theme-color, $cx-b-fg-theme-color)),
        ("gold-theme": ($cx-gld-bg-theme-color, $cx-b-fg-theme-color)),
        ("pink-theme": ($cx-pnk-bg-theme-color, $cx-b-fg-theme-color)),
        ("brown-theme": ($cx-brw-bg-theme-color, $cx-w-fg-theme-color)),
        ("red-theme": ($cx-red-bg-theme-color, $cx-w-fg-theme-color)),
        ("blue-theme": ($cx-blu-bg-theme-color, $cx-w-fg-theme-color)),
        ("blue-cyan-theme": ($cx-blu-cyan-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-dark-theme": ($cx-blu-dark-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-light-theme": ($cx-blu-light-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-lighter-theme": ($cx-blu-lighter-bg-theme-color, $cx-b-fg-theme-color)),
        ("green-theme": ($cx-grn-bg-theme-color, $cx-w-fg-theme-color)),
        ("yellow-theme": ($cx-yel-bg-theme-color, $cx-b-fg-theme-color)),
        ("white-theme": ($cx-whi-bg-theme-color, $cx-b-fg-theme-color)),
        ("light-theme": ($cx-lig-bg-theme-color, $cx-b-fg-theme-color)),
        ("success-info-theme": ($cx-success-info-theme-color, $cx-b-fg-theme-color)),
        ("success-info-dark-theme": ($cx-success-info-dark-theme-color, $cx-b-fg-theme-color)),
        ("success-info-border-theme": ($cx-success-info-border-theme-color, $cx-b-fg-theme-color)),
        ("warning-info-theme": ($cx-warning-info-theme-color, $cx-b-fg-theme-color)),
        ("warning-info-dark-theme": ($cx-warning-info-dark-theme-color, $cx-b-fg-theme-color)),
        ("warning-info-border-theme": ($cx-warning-info-border-theme-color, $cx-b-fg-theme-color)),
        ("danger-info-theme": ($cx-danger-info-theme-color, $cx-b-fg-theme-color)),
        ("danger-info-dark-theme": ($cx-danger-info-dark-theme-color, $cx-b-fg-theme-color)),
        ("danger-info-border-theme": ($cx-danger-info-border-theme-color, $cx-b-fg-theme-color)),
        ("disabled-info-theme": ($cx-disabled-info-theme-color, $cx-b-fg-theme-color)),
        ("disabled-info-dark-theme": ($cx-disabled-info-dark-theme-color, $cx-b-fg-theme-color)),
        ("disabled-info-border-theme": ($cx-disabled-info-border-theme-color, $cx-b-fg-theme-color)),
        ("info-info-theme": ($cx-info-info-theme-color, $cx-w-fg-theme-color)),
        ("info-info-dark-theme": ($cx-info-info-dark-theme-color, $cx-b-fg-theme-color)),
        ("info-info-border-theme": ($cx-info-info-border-theme-color, $cx-b-fg-theme-color)),
    ),

    // hover themes
    "hover-theme-colors": ( // google
        ("dark-theme": ($cx-hover-drk-bg-theme-color, $cx-w-fg-theme-color)),
        ("black-theme": ($cx-hover-blk-bg-theme-color, $cx-w-fg-theme-color)),
        ("grey-theme": ($cx-hover-gry-bg-theme-color, $cx-b-fg-theme-color)),
        ("sky-theme": ($cx-hover-sky-bg-theme-color, $cx-b-fg-theme-color)),
        ("gold-theme": ($cx-hover-gld-bg-theme-color, $cx-b-fg-theme-color)),
        ("pink-theme": ($cx-hover-pnk-bg-theme-color, $cx-b-fg-theme-color)),
        ("brown-theme": ($cx-hover-brw-bg-theme-color, $cx-w-fg-theme-color)),
        ("red-theme": ($cx-hover-red-bg-theme-color, $cx-w-fg-theme-color)),
        ("blue-theme": ($cx-hover-blu-bg-theme-color, $cx-w-fg-theme-color)),
        ("blue-cyan-theme": ($cx-hover-blu-cyan-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-dark-theme": ($cx-hover-blu-dark-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-light-theme": ($cx-hover-blu-light-bg-theme-color, $cx-hover-blu-bg-theme-color)),
        ("blue-lighter-theme": ($cx-hover-blu-lighter-bg-theme-color, $cx-b-fg-theme-color)),
        ("green-theme": ($cx-hover-grn-bg-theme-color, $cx-w-fg-theme-color)),
        ("yellow-theme": ($cx-hover-yel-bg-theme-color, $cx-b-fg-theme-color)),
        ("white-theme": ($cx-hover-whi-bg-theme-color, $cx-b-fg-theme-color)),
        ("light-theme": ($cx-hover-lig-bg-theme-color, $cx-b-fg-theme-color)),
    ),

    // transparent themes
    "transparent-theme-colors": ( // google
        ("dark-theme": ($cx-transparent-drk-bg-theme-color, $cx-w-fg-theme-color)),
        ("black-theme": ($cx-transparent-blk-bg-theme-color, $cx-w-fg-theme-color)),
        ("grey-theme": ($cx-transparent-gry-bg-theme-color, $cx-b-fg-theme-color)),
        ("sky-theme": ($cx-transparent-sky-bg-theme-color, $cx-b-fg-theme-color)),
        ("gold-theme": ($cx-transparent-gld-bg-theme-color, $cx-b-fg-theme-color)),
        ("pink-theme": ($cx-transparent-pnk-bg-theme-color, $cx-b-fg-theme-color)),
        ("brown-theme": ($cx-transparent-brw-bg-theme-color, $cx-w-fg-theme-color)),
        ("red-theme": ($cx-transparent-red-bg-theme-color, $cx-w-fg-theme-color)),
        ("blue-theme": ($cx-transparent-blu-bg-theme-color, $cx-w-fg-theme-color)),
        ("blue-cyan-theme": ($cx-transparent-blu-cyan-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-dark-theme": ($cx-transparent-blu-dark-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-light-theme": ($cx-transparent-blu-light-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-lighter-theme": ($cx-transparent-blu-lighter-bg-theme-color, $cx-b-fg-theme-color)),
        ("green-theme": ($cx-transparent-grn-bg-theme-color, $cx-w-fg-theme-color)),
        ("yellow-theme": ($cx-transparent-yel-bg-theme-color, $cx-b-fg-theme-color)),
        ("white-theme": ($cx-transparent-whi-bg-theme-color, $cx-b-fg-theme-color)),
        ("light-theme": ($cx-transparent-lig-bg-theme-color, $cx-b-fg-theme-color)),
        ("theme": ($cx-transparent-none-bg-theme-color, $cx-b-fg-theme-color)),
    ),

    // selected themes
    "selected-theme-colors": ( // google
        ("dark-theme": ($cx-selected-drk-bg-theme-color, $cx-w-fg-theme-color)),
        ("black-theme": ($cx-selected-blk-bg-theme-color, $cx-w-fg-theme-color)),
        ("grey-theme": ($cx-selected-gry-bg-theme-color, $cx-b-fg-theme-color)),
        ("sky-theme": ($cx-selected-sky-bg-theme-color, $cx-b-fg-theme-color)),
        ("gold-theme": ($cx-selected-gld-bg-theme-color, $cx-b-fg-theme-color)),
        ("pink-theme": ($cx-selected-pnk-bg-theme-color, $cx-b-fg-theme-color)),
        ("brown-theme": ($cx-selected-brw-bg-theme-color, $cx-w-fg-theme-color)),
        ("red-theme": ($cx-selected-red-bg-theme-color, $cx-w-fg-theme-color)),
        ("blue-theme": ($cx-selected-blu-bg-theme-color, $cx-w-fg-theme-color)),
        ("blue-cyan-theme": ($cx-selected-blu-cyan-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-dark-theme": ($cx-selected-blu-dark-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-light-theme": ($cx-selected-blu-light-bg-theme-color, $cx-b-fg-theme-color)),
        ("blue-lighter-theme": ($cx-selected-blu-lighter-bg-theme-color, $cx-b-fg-theme-color)),
        ("green-theme": ($cx-selected-grn-bg-theme-color, $cx-w-fg-theme-color)),
        ("yellow-theme": ($cx-selected-yel-bg-theme-color, $cx-b-fg-theme-color)),
        ("white-theme": ($cx-selected-whi-bg-theme-color, $cx-b-fg-theme-color)),
        ("light-theme": ($cx-selected-lig-bg-theme-color, $cx-b-fg-theme-color)),
    )
);

@each $theme in map.get($themes, "theme-colors") {
    @each $theme_name in map-keys($theme) {
        .bg-#{$theme_name} {
            background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
        }

        // background color fade
        .bg-#{$theme_name}-fade-top {
            background: linear-gradient(to top, #{nth(map.get($theme, $theme_name), 1)}, transparent) !important;
        }

        .bg-#{$theme_name}-fade-left {
            background: linear-gradient(to left, #{nth(map.get($theme, $theme_name), 1)}, transparent) !important;
        }

        .bg-#{$theme_name}-fade-bottom {
            background: linear-gradient(to bottom, #{nth(map.get($theme, $theme_name), 1)}, transparent) !important;
        }

        .bg-#{$theme_name}-fade-right {
            background: linear-gradient(to right, #{nth(map.get($theme, $theme_name), 1)}, transparent) !important;
        }

        // table 
        .table-striped-#{$theme_name} tbody tr:nth-child(odd) {
            background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
        }

        // button theme
        .btn-#{$theme_name} {
            background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
            color: #{nth(map.get($theme, $theme_name), 2)};
            opacity: .9;
        }

        .btn-#{$theme_name}:active,
        .btn-#{$theme_name}:hover,
        .btn-#{$theme_name}:focus {
            color: #{nth(map.get($theme, $theme_name), 2)};
            opacity: 1;
            // box-shadow: 0px 0px 8px -5px rgba(0, 0, 0.3) !important;
        }

        // flat icon button theme
        .flat-icon-btn-#{$theme_name} {
            background-color: transparent !important;
            color: #{nth(map.get($theme, $theme_name), 2)};
            opacity: .9;
        }

        .flat-icon-btn-#{$theme_name}:active,
        .flat-icon-btn-#{$theme_name}:hover,
        .flat-icon-btn-#{$theme_name}:focus {
            color: #{nth(map.get($theme, $theme_name), 2)};
            background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
            opacity: 1;
            box-shadow: 0px 0px 8px -5px rgba(0, 0, 0.3) !important;
        }

        // token theme
        .token-#{$theme_name} {
            background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
            color: #{nth(map.get($theme, $theme_name), 2)};
            // opacity: .9;
        }

        // button hover theme
        .button-hover-#{$theme_name} * {
            outline: none;
        }

        // menu hover theme
        .menu-hover-#{$theme_name} * {
            outline: none;
        }

        // text theme
        .text-#{$theme_name} {
            color: #{nth(map.get($theme, $theme_name), 1)} !important;
        }

        // border theme
        .border-#{$theme_name} {
            border-color: #{nth(map.get($theme, $theme_name), 1)} !important;
        }


        // before theme
        .before-#{$theme_name} {
            &::before {
                content: '';
                background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
            }
        }

        // after theme
        .after-#{$theme_name} {
            &::after {
                content: '';
                background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
            }
        }
    }
}

@each $theme in map.get($themes, "hover-theme-colors") {
    @each $theme_name in map-keys($theme) {
        // .hover-bg-#{$theme_name}:hover *:first-child {
        //     background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
        //     color: #{nth(map.get($theme, $theme_name), 2)} !important;
        // }
        .hover-bg-#{$theme_name}:hover div[aria-controls="selector"] {
            background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
            color: #{nth(map.get($theme, $theme_name), 2)} !important;
        }
    }
}

@each $theme in map.get($themes, "transparent-theme-colors") {
    @each $theme_name in map-keys($theme) {

        // transparent background
        .transparent-bg-#{$theme_name} {
            background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
        }

        // backdrop
        .backdrop-#{$theme_name} {
            background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
        }

        // border theme
        .transparent-border-#{$theme_name} {
            border-color: #{nth(map.get($theme, $theme_name), 1)} !important;
        }
    }
}

@each $theme in map.get($themes, "selected-theme-colors") {
    @each $theme_name in map-keys($theme) {
        .selected-bg-#{$theme_name} {
            background-color: #{nth(map.get($theme, $theme_name), 1)} !important;
        }
    }
}


// ********************************************
//                  DATE PICKER               *
// ********************************************

.react-datepicker-popper {
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(-3px, 35.0px) !important;
    will-change: transform;
}

.react-datepicker-popper .react-datepicker__triangle {
    stroke: #aeaeae !important;
    display: none !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    fill: #f0f0f0 !important;
    color: #f0f0f0 !important;
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif !important;
    font-size: 0.8rem !important;
    background-color: #fff !important;
    color: #{$cx-blu-bg-theme-color} !important;
    border: 1px solid #{$cx-blu-light-bg-theme-color} !important;
    border-radius: 0.3rem !important;
    display: inline-block !important;
    position: relative !important;
    line-height: initial !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    ;
}

.react-datepicker__input-container input {
    color: #{$cx-blu-bg-theme-color} !important;
    cursor: pointer !important;
}

.react-datepicker-ignore-onclickoutside {
    font-size: #{calc(13/16)}#{'rem'} !important;
}

.react-datepicker-wrapper {
    display: flex !important;
    justify-content: start;
    align-items: center;
    padding: 0;
    border: 0;
    font-size: #{calc(14/16)}#{'rem'} !important;
}

.react-datepicker__header {
    text-align: center;
    background-color: #fff !important;
    border-bottom: none !important;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #{$cx-blu-bg-theme-color} !important;
    font-weight: bold;
    font-size: #{calc(14/16)}#{'rem'} !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #{$cx-blu-bg-theme-color} !important;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #{$cx-blu-bg-theme-color} !important;
    color: #fff !important;
}

.react-datepicker__day--keyboard-selected:not([aria-disabled=true]):hover,
.react-datepicker__month-text--keyboard-selected:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--keyboard-selected:not([aria-disabled=true]):hover,
.react-datepicker__year-text--keyboard-selected:not([aria-disabled=true]):hover {
    background-color: #{$cx-blu-bg-theme-color} !important;
    color: #fff !important;
}

.react-datepicker__day--selected:not([aria-disabled=true]):hover,
.react-datepicker__day--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__day--in-range:not([aria-disabled=true]):hover,
.react-datepicker__month-text--selected:not([aria-disabled=true]):hover,
.react-datepicker__month-text--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__month-text--in-range:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--selected:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--in-range:not([aria-disabled=true]):hover,
.react-datepicker__year-text--selected:not([aria-disabled=true]):hover,
.react-datepicker__year-text--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__year-text--in-range:not([aria-disabled=true]):hover {
    background-color: #{$cx-blu-bg-theme-color} !important;
    color: #fff !important;
}