@use "sass:map";

// **********************************************
// *                 ::BANNER::                 *
// **********************************************

$banners: (
    ("name": "1", "src": "banner-1.jpg"), 
    ("name": "2", "src": "banner-2.jpg"), 
    ("name": "3", "src": "banner-3.jpg"), 
    ("name": "4", "src": "banner-4.jpg"), 
    ("name": "5", "src": "banner-5.jpg"), 
    ("name": "6", "src": "banner-6.jpg"), 
    ("name": "7", "src": "banner-7.jpg"), 
    ("name": "8", "src": "banner-8.jpg") 
);

@each $banner in $banners {
    .banner-#{map-get($banner, "name")} {
        background-image: url("/shared/assets/images/banners/" + map-get($banner, "src"));
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}


// **********************************************
// *                  ::LOGO::                  *
// **********************************************

$logos: (
    ("name": "default", "src": "logo.svg"), 
    ("name": "alternative", "src": "logo.alternative.svg"), 
    ("name": "default-white", "src": "logo.white.svg"), 
);

@each $logo in $logos {
    .logo-#{map-get($logo, "name")} {
        background-image: url("/shared/assets/images/logos/" + map-get($logo, "src"));
        background-repeat: no-repeat;
    }
}


// **********************************************
// *             ::ILLUSTRATION::               *
// **********************************************

$illustrations: (
    ("name": "404-error", "src": "404.jpg"), 
);

@each $illustration in $illustrations {
    .illustration-#{map-get($illustration, "name")} {
        background-image: url("/shared/assets/images/illustrations/" + map-get($illustration, "src"));
        background-position: center;
        background-repeat: no-repeat;
    }
}


// **********************************************
// *                 IMAGE SIZE                 *
// **********************************************

$background-size-enum: (
    ("name": "cover"), 
    ("name": "contain"), 
    ("name": "auto"), 
);

@each $size in $background-size-enum {
    .image-#{map-get($size, "name")} {
        background-size: #{map-get($size, "name")} !important;
    }
}

$dimension-sizes: 100;
@for $size from 0 to $dimension-sizes {
    .image-#{$size} {
        background-size: #{$size}#{'%'} !important;
    }
}


// **********************************************
// *               IMAGE POSITION               *
// **********************************************

$background-positions: (
    ("name": "left"), 
    ("name": "center"), 
    ("name": "right"), 
);

@each $position in $background-positions {
    .image-#{map-get($position, "name")} {
        background-position: #{map-get($position, "name")} !important;
    }
}