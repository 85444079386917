@use "sass:map";

$font-faces: (

    // lato
    ("font-name": "lato-regular", "font-family": "lato regular", "src": "lato/lato-regular.ttf"),

    // manrope
    ("font-name": "manrope-bold", "font-family": "manrope bold", "src": "manrope/manrope-bold.ttf"),
    ("font-name": "manrope-extra-bold", "font-family": "manrope extra bold", "src": "manrope/manrope-extra-bold.ttf"),
    ("font-name": "manrope-extra-light", "font-family": "manrope extra light", "src": "manrope/manrope-extra-light.ttf"),
    ("font-name": "manrope-light", "font-family": "manrope light", "src": "manrope/manrope-light.ttf"),
    ("font-name": "manrope-medium", "font-family": "manrope medium", "src": "manrope/manrope-medium.ttf"),
    ("font-name": "manrope-regular", "font-family": "manrope regular", "src": "manrope/manrope-regular.ttf"),
    ("font-name": "manrope-semi-bold", "font-family": "manrope semi bold", "src": "manrope/manrope-semi-bold.ttf"),

    // open sans
    ("font-name": "open-sans-bold", "font-family": "open sans bold", "src": "open-sans/open-sans-bold.ttf"),
    ("font-name": "open-sans-extra-bold", "font-family": "open sans extra bold", "src": "open-sans/open-sans-extra-bold.ttf"),
    ("font-name": "open-sans-light", "font-family": "open sans light", "src": "open-sans/open-sans-light.ttf"),
    ("font-name": "open-sans-medium", "font-family": "open sans medium", "src": "open-sans/open-sans-medium.ttf"),
    ("font-name": "open-sans-regular", "font-family": "open sans regular", "src": "open-sans/open-sans-regular.ttf"),
    ("font-name": "open-sans-semi-bold", "font-family": "open sans semi bold", "src": "open-sans/open-sans-semi-bold.ttf"),

    // public sans
    ("font-name": "public-sans-black", "font-family": "public sans black", "src": "public-sans/public-sans-black.ttf"),
    ("font-name": "public-sans-bold", "font-family": "public sans bold", "src": "public-sans/public-sans-bold.ttf"),
    ("font-name": "public-sans-extra bold", "font-family": "public sans extra bold", "src": "public-sans/public-sans-extra-bold.ttf"),
    ("font-name": "public-sans-light", "font-family": "public sans light", "src": "public-sans/public-sans-light.ttf"),
    ("font-name": "public-sans-medium", "font-family": "public sans medium", "src": "public-sans/public-sans-medium.ttf"),
    ("font-name": "public-sans-regular", "font-family": "public sans regular", "src": "public-sans/public-sans-regular.ttf"),
    ("font-name": "public-sans-semi-bold", "font-family": "public sans semi bold", "src": "public-sans/public-sans-semi-bold.ttf"),
    ("font-name": "public-sans-thin", "font-family": "public sans thin", "src": "public-sans/public-sans-thin.ttf"),
);

@each $font in $font-faces {
    @font-face {
        font-family: map-get($font, "font-family");
        src: url("/shared/assets/fonts/" + map-get($font, "src")) format("truetype");
    }

    .#{map-get($font, "font-name")} {
        font-family: map-get($font, "font-family");
    }
}