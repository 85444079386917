@use "sass:map";

body {
    margin: 0;
    font-family: "manrope medium";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    overflow: hidden;
}


/* TEXT */
$text: (
    "align": ("left", "right", "center", "justify"),
    "wrap": ("normal", "nowrap", "wrap"),
    "overflow": ("clip", "ellipsis", "none"),
    "transform": ("capitalize", "lowercase", "uppercase"),
    "decoration": (("type":"overline"), ("type":"line-through"), ("type":"underline"), ("type":"underline overline"), ("type":"none")),
    "weight": (("light":"400"), ("lighter":"lighter"), ("normal":"500"), ("bold":"600"), ("bolder":"bolder")),
    "style": ("italic", "normal", "oblique"),
    "reset": ("none", "unset"),
    "resize": ("none")
);

@each $pos in map.get($text, "align") {
    .align-#{$pos} {
        text-align: #{$pos} !important;
    }
}

@each $wrap in map.get($text, "wrap") {
    .text-#{$wrap} {
        white-space: #{$wrap} !important;
    }
}

@each $resize in map.get($text, "resize") {
    .text-resize-#{$resize} {
        resize: #{$resize} !important;
    }
}

@each $transform in map.get($text, "transform") {
    .text-#{$transform} {
        text-transform: #{$transform} !important;
    }
}

@each $decoration in map.get($text, "decoration") {
    @each $type in map.get($decoration, "type") {
        .text-decoration-#{$type} {
            text-decoration: #{$type} !important;
        }
    }
}

@each $weight in map.get($text, "weight") {
    @each $key in map-keys($weight) {
        .text-#{$key} {
            font-weight: #{map.get($weight, $key)} !important;
        }
    }
}

@each $style in map.get($text, "style") {
    .text-#{$style} {
        font-style: #{$style} !important;
    }
}

@each $style in map.get($text, "overflow") {
    .text-overflow-#{$style} {
        text-overflow: #{$style} !important;
        overflow: hidden !important;
    }
}



// **********************************************
// *                     FORM                   *
// **********************************************

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-color: transparent !important;
    border: none !important;
    outline: none !important;
}

input {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-color: transparent !important;
    border: none !important;
    outline: none !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=button]:focus,
input[type=submit]:focus,
input[type=checkbox]:focus,
input[type=radio]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
button:focus,
select {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
}



// **********************************************
// *                CHECKBOX                    *
// **********************************************
input[type=checkbox],
input[type=radio] {
    outline: none;
    // box-shadow: 0 0 0 10px rgba($color: #000000, $alpha: 1.0) !important;
    // border-radius: 100%;
}



// **********************************************
// *                     MOUSE                  *
// **********************************************

$cursors: (
    "alias",
    "all-scroll",
    "default",
    "cell",
    "context-menu",
    "col-resize",
    "copy",
    "crosshair",
    "e-resize",
    "ew-resize",
    "grab",
    "grabbing",
    "help",
    "move",
    "n-resize",
    "ne-resize",
    "nesw-resize",
    "ns-resize",
    "nw-resize",
    "nwse-resize",
    "no-drop",
    "none",
    "not-allowed",
    "pointer",
    "progress",
    "row-resize",
    "s-resize",
    "se-resize",
    "sw-resize",
    "text",
    "w-resize",
    "wait",
    "zoom-in",
    "zoom-out",
    "unset"
);

@each $type in $cursors {
    .cursor-#{$type} {
        cursor: #{$type};
    }
}



// **********************************************
// *          BACKGROUND IMAGE COVER            *
// **********************************************

$background_size_mode: (
    "cover",
    "contain",
    "auto",
    "inherit",
    "none"
);

@each $size in $background_size_mode {
    .bg-#{$size} {
        background-size: #{$size};
        background-repeat: no-repeat;
    }
}



// **********************************************
// *          BACKGROUND IMAGE POSITION            *
// **********************************************

$background_position_type: (
    "center",
    "left",
    "right"
);

@each $position in $background_position_type {
    .bg-#{$position} {
        background-position: #{$position};
    }
}



// **********************************************
// *                    BORDER                  *
// **********************************************

$border: (
    "solid",
    "none",
    "dash",
    "dotted"
);

@each $style in $border {
    .border-#{$style} {
        border-style: #{$style};
    }
}



// **********************************************
// *                    DISPLAY                 *
// **********************************************

$display: (
    "block",
    "none",
    "flex",
    "inline-block"
);

@each $type in $display {
    .display-#{$type} {
        display: #{$type};
    }
}



// **********************************************
// *                 TABLE LAYOUT               *
// **********************************************

$table_layout: (
    "fixed",
    "initial",
    "inherit",
    "unset"
);

@each $type in $table_layout {
    .table-layout-#{$type} {
        table-layout: #{$type};
    }
}



// **********************************************
// *                  WHITE-SPACE               *
// **********************************************

$whitespace: (
    "nowrap",
    "none",
    "pre",
    "wrap"
);

@each $type in $whitespace {
    .white-space-#{$type} {
        white-space: #{$type};
    }
}



// **********************************************
// *                   OVERFLOW                 *
// **********************************************

$overflow: (
    "hidden",
    "none",
    "scroll",
    "auto"
);

@each $type in $overflow {
    .overflow-#{$type} {
        overflow: #{$type};
    }

    .overflow-x-#{$type} {
        overflow-x: #{$type};
    }

    .overflow-y-#{$type} {
        overflow-y: #{$type};
    }
}



// **********************************************
// *                 BORDER STYLE               *
// **********************************************

$borderStyle: (
    "dashed",
    "dotted",
    "solid",
    "double",
    "groove",
    "inset",
    "outset",
    "ridge",
    "hidden",
    "none"
);

@each $type in $borderStyle {
    .border-#{$type} {
        border-style: #{$type} !important;
    }
}



// **********************************************
// *                    TOGGLE                  *
// **********************************************

.show {
    display: block !important;
}

.hide {
    display: none !important;
}



// **********************************************
// *             ::PSEUDO-ELEMENT::             *
// **********************************************

.before {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.after {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}



// **********************************************
// *                 BOX-SHADOW                 *
// **********************************************
.box-shadow-left {
    // box-shadow: 0 0.125rem 0.25rem 0.125rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 5rem 0 5rem -5rem rgba(0, 0, 0, 0.175) !important;
}

.box-shadow-right {
    // box-shadow: 0 0.125rem 0.25rem 0rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 5rem 0 5rem -5rem rgba(0, 0, 0, 0.247) !important;
}



// **********************************************
// *                  ANIMATION                 *
// **********************************************

$transition: (
    "slide-horizontal": ("left", "right"),
    "slide-vertical": ("top", "bottom"),
    "transition": ("ease-in", "ease-out", "ease-in-out"),
    "anim": ("infinite-loader")
);

@each $pos in map.get($transition, "slide-horizontal") {
    .slide-#{$pos} {
        opacity: 1;
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }
}

@each $pos in map.get($transition, "slide-vertical") {
    .slide-#{$pos} {
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }
}

@each $pos in map.get($transition, "anim") {
    .transition-#{$pos} {
        transition: #{$pos}#{" 0.5s"} !important;
    }
}

@each $pos in map.get($transition, "anim") {
    .anim-#{$pos} {
        animation: #{"infiniteLoaderAnim"}#{" 1s"}#{" linear infinite"} !important;
    }
}

@keyframes infiniteLoaderAnim {
    0% {
        left: 0%;
        right: 100%;
        width: 0%;
    }

    10% {
        left: 0%;
        right: 75%;
        width: 25%;
    }

    90% {
        right: 0%;
        left: 75%;
        width: 25%;
    }

    100% {
        left: 100%;
        right: 0%;
        width: 0%;
    }
}



// **********************************************
// *                   DROPDOWN                 *
// **********************************************

$dropdown: (
    "top",
    "left",
    "bottom",
    "right"
);

@each $type in $dropdown {
    .dropdown-#{$type} {
        position: relative;
    }

    .dropdown-#{$type} .dropdown-button {
        outline: none;
    }

    .dropdown-#{$type} .dropdown-callout {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: visibility .5s ease-in-out, opacity .5s ease-in-out;
    }

    .dropdown-#{$type}:hover .dropdown-button {
        outline: none;
    }

    .dropdown-#{$type}:hover .dropdown-callout {
        visibility: visible;
        opacity: 1;
        transition: visibility .5s ease-in-out, opacity .5s ease-in-out;
    }
}



// **********************************************
// *             OVERFLOW ON HOVER              *
// **********************************************

$overflow-hover: (
    "auto",
    "hidden",
    "scroll",
    "visible"
);

@each $type in $overflow-hover {
    .hover-#{$type}:hover {
        overflow: #{$type};
    }

    .hover-x-#{$type}:hover {
        overflow: hidden;
        overflow-x: #{$type};
    }

    .hover-y-#{$type}:hover {
        overflow: hidden;
        overflow-y: #{$type};
    }
}



// **********************************************
// *                  BOX-SIZING                *
// **********************************************

$boxsizing: (
    "border": "border-box",
    "content": "content-box",
);


// @each $theme in map.get($themes, "theme-colors") {
//     @each $theme_name in map-keys($theme) {

@each $type in map-keys($boxsizing) {
    .box-sizing-#{$type} {
        box-sizing: #{map.get($boxsizing, $type)} !important;
    }
}



// **********************************************
// *                 BLOCKQUOTE                 *
// **********************************************

$blockquote-size: 64;
$rem-text-size: 0.625;
$px-text-size: 10;

@for $i from 0 to $blockquote-size {
    .blockquote-#{$i}px {
        padding-top: 10px;
        padding-left: 10px;

        &::before {
            content: '“';
            position: absolute;
            top: 0;
            left: 0;
            font-size: #{calc(calc($rem-text-size * $i) / $px-text-size)}rem;
            line-height: 20px;
        }

        &::after {
            content: '”';
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: #{calc(calc($rem-text-size * $i) / $px-text-size)}rem;
            line-height: 20px;
        }
    }

}



// *********************************************
// *                  NAV TAB                  *
// *********************************************

.nav {
    outline: none;
}

.nav .nav-tabs {
    outline: none;
}

.nav .nav-tabs .nav-item {
    outline: none;
}

.nav .nav-tabs .nav-item .nav-link {
    outline: none;
}



// ********************************************
// *                DROPDOWNLIST              *
// ********************************************

.dropdown-list {
    outline: none;
}

.dropdown-list .select-button {
    outline: none;
}

.dropdown-list .select-dropdown {
    outline: none;
}



// ********************************************
// *                SCROLLBAR                 *
// ********************************************
*::-webkit-scrollbar {
    width: 5px;
    height: 1px;
    // background-color: #aaa;
    /* or add it to the track */
}

/* Add a thumb */
*::-webkit-scrollbar-thumb {
    background: #aaa;
}


.auto-size div:first-of-type {
    width: 100%;
}



// ********************************************
// *                   GRID                   *
// ********************************************
.grid-container {
    display: grid;
}


// ********************************************
// *                POPOVER                   *
// ********************************************
.popover {
    outline: none;
}

.popover .popover-header {
    outline: none;
    background: none !important;
    border-bottom: none !important;
}

.popover .popover-body {
    outline: none;
    padding-top: 2px !important;
}

.popover .popover-title {
    outline: none;
}
